import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import imageStatements from 'url:../static/shapes/upload-statements.png';
import { z } from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { publicConfig } from '../../../../functions/src/shared/config';
import { notNullish } from '../../../../functions/src/shared/utils';

import { Upload } from '../../shared/components/upload';
import { trpc } from '../../shared/trpc/client';
import { uploadLeaseAgreementsOptions } from '../../shared/upload-lease-agreements';

import { Button, Spacing, Text } from '../../base-ui/components';
import { colors } from '../../base-ui/variables';

import { Spinner } from '../components/profile/credit-builder-card/credit-builder-card.styles';
import { useUserData } from '../contexts/hooks';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

type Props = {
	onNext: () => void;
	manualResidencyId?: string;
};

const Img = styled.img`
	@media (max-width: 450px) {
		left: 0;
	}
	position: relative;
	top: 0;
	left: 10%;
`;

const locationStateSchema = z.object({ isLeaseRenewal: z.boolean() });

const ResidencyAddress = (props: { manualResidencyId: string | undefined }) => {
	const { home } = useUserData();

	const { manualResidencyId } = props;
	const { data, isLoading } = trpc.user.findManualResidency.useQuery(
		{ manualResidencyId },
		{
			enabled: Boolean(manualResidencyId),
		},
	);
	if (manualResidencyId && isLoading) {
		return <Spinner $spinnerColor="green" />;
	}
	const homeResidency = home?.residency;
	const address = manualResidencyId
		? [data?.streetAddress, data?.apartment, data?.postalCode, data?.city, data?.state]
		: [
				homeResidency?.streetAddress,
				homeResidency?.apartment,
				homeResidency?.postalCode,
				homeResidency?.city,
				homeResidency?.state,
			];
	const validAddress = address.filter(notNullish);

	if (!validAddress.length) {
		return null;
	}
	return (
		<div>
			<Text center style={{ marginBottom: '0.5rem' }}>
				<FormattedMessage
					defaultMessage="Please upload your rental lease agreement for"
					id="upload-lease-agreement.address"
				/>
				: <br />
				{address.filter(notNullish).join(', ')}
			</Text>
		</div>
	);
};

export const UploadLeaseAgreement = ({ onNext, manualResidencyId }: Props) => {
	const { user } = useUserData();
	const trpcUtils = trpc.useUtils();

	const trackOnboardingEvent = useTrackOnboardingEvent();
	const leaseAgreementUploadReminder = trpc.user.remindLeaseAgreementUpload.useMutation();

	useEffect(() => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingLeaseAgreementUploadStart });
	}, []);

	useEffect(() => {
		if (!user?.userId) return;

		leaseAgreementUploadReminder.mutate({ userId: user?.userId });
	}, [user]);

	const buttonHandler = () => {
		trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingLeaseAgreementUploadEnd });
		trpcUtils.user.home.invalidate();
		trpcUtils.creditReporting.creditBuilderHistoryRelatedInfo.invalidate();
		onNext();
	};
	const location = useLocation();

	const state = locationStateSchema.safeParse(location.state);
	const customMetadata: Record<string, string> = {};
	if (state.success) {
		customMetadata['isLeaseRenewal'] = state.data.isLeaseRenewal.toString();
	}
	if (manualResidencyId) {
		customMetadata[publicConfig.leaseAgreements.uploadMetadata.manualResidencyId] = manualResidencyId;
	}
	return (
		<>
			<Img src={imageStatements} width="420px" height="250px" />
			<Upload
				{...uploadLeaseAgreementsOptions(user?.userId ?? '')}
				customMetadata={customMetadata}
				header={
					<div style={{ marginTop: '25px' }}>
						<Text type="title" center>
							<FormattedMessage
								defaultMessage="Upload your lease agreement"
								id="upload-lease-agreement.title"
							/>
						</Text>
						<Spacing $size="s" />
						<Text center>
							<FormattedMessage
								defaultMessage="Your lease details are securely recorded in our system as required by the credit bureaus."
								id="upload-lease-agreement.description"
							/>
						</Text>
						<Spacing $size="m" />
						<ResidencyAddress manualResidencyId={manualResidencyId} />
						<Spacing $size="l" />
					</div>
				}
				buttonComp={Button}
				backButton={
					<Button type="link" onClick={buttonHandler} testId="onboarding-lease-agreement-upload-later-button">
						<FormattedMessage defaultMessage="I'll upload later" id="upload-lease-agreement.back-button" />
					</Button>
				}
				dragAndDropText={
					<p
						style={{
							padding: '5px 10px 10px 5px',
							textAlign: 'center',
							fontSize: '2rem',
							color: colors.teal,
							fontWeight: '600',
						}}
					>
						<FormattedMessage
							defaultMessage="Browse or drag and drop your files here"
							id="upload-lease-agreement.drag-and-drop-text"
						/>
					</p>
				}
				uploadOnDrop
				onContinue={buttonHandler}
			/>
		</>
	);
};
