import React, { ReactNode, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { CreditSubjectRentReportingStatus } from '../../../../functions/src/shared/credit-reporting';
import { LandlordCustomRent, PropertyManagerCreditBuilder } from '../../../../functions/src/shared/landlords';
import { FeatureSwitch, User, userHasFeatureEnabled } from '../../../../functions/src/shared/user';

import { Country } from '../../shared/country';
import { getEnvironment } from '../../shared/environment';
import { isImpersonating } from '../../shared/impersonation';
import { RouterOutput, trpc } from '../../shared/trpc/client';
import * as zendesk from '../../shared/zendesk';

import { links } from '../Router/paths';
import { FirebaseContextState } from './Firebase';
import { useFirebase, useInterface } from './hooks';

export enum UserStatus {
	Loading = 'Loading',
	Unauthenticated = 'Unauthenticated',

	Partner = 'Partner',
	OnboardingVerifyEmail = 'OnboardingVerifyEmail',
	OnboardingEnrollPhone = 'OnboardingEnrollPhone',
	OnboardingAddress = 'OnboardingAddress',
	PendingOnboardingSurvey = 'PendingOnboardingSurvey',
	PendingLandlordPaymentMethod = 'PendingLandlordPaymentMethod',
	OnboardingSelectRentAmount = 'OnboardingSelectRentAmount',
	OnboardingRoommatesRentAmountConfirm = 'OnboardingRoommatesRentAmountConfirmed',
	OnboardingSelectDates = 'OnboardingSelectDates',
	CountryNotSupported = 'CountryNotSupported',
	OnboardingBank = 'OnboardingBank',
	OnboardingLineOfCreditReporting = 'OnboardingLineOfCreditReporting',
	OnboardingConfirm = 'OnboardingConfirm',
	OnboardingCustomRentWaitlist = 'OnboardingCustomRentWaitlist',
	// credit builder
	OnboardingCreditBuilderSetup = 'onboarding_credit_builder_setup',
	OnboardingCreditBuilderSuccess = 'onboarding_credit_builder_success',
	OnboardingCreditBuilderPaymentCompleted = 'onboarding_credit_builder_payment_completed',
	PromoCreditBuilderSetup = 'promo_credit_builder_setup',
	PromoCreditBuilderSuccess = 'promo_credit_builder_success',
	OnboardingCreditBuilderHistoryUpsell = 'onboarding_credit_builder_history_upsell',
	OnboardingCreditBuilderSubscriptionSelection = 'onboarding_credit_builder_subscription_selection',

	OnboardingDirectDebitSetup = 'onboarding_direct_debit_setup',
	OnboardingDirectDebitCardDetails = 'onboarding_direct_debit_card_details',
	OnboardingDirectDebitCardSuccess = 'onboarding_direct_debit_card_success',

	// other
	SuccessSteps = 'SuccessSteps',

	ValidUser = 'ValidUser',

	Admin = 'Admin',
}

interface UserDataState {
	user: User | null;
	home: RouterOutput['user']['home'] | null;
	userStatus: UserStatus;
	canExitOnboarding: boolean;
	hasLineOfCreditReportingEnabled: boolean;
	hasRentReportingEnabled: boolean;
	customRentSupported: boolean;
	handlers: {
		// Re-fetches user status, while showing the interface loader
		forceRefetch: () => Promise<void>;
		silentRefetch: () => void;
	};
	onboardingEvents: Partial<Record<BusinessEventType, Date>>;
}

const initialState: UserDataState = {
	user: null,
	home: null,
	userStatus: UserStatus.Loading,
	canExitOnboarding: false,
	hasLineOfCreditReportingEnabled: false,
	hasRentReportingEnabled: false,
	customRentSupported: false,
	handlers: {
		forceRefetch: async () => {
			return;
		},
		silentRefetch: async () => {
			return;
		},
	},
	onboardingEvents: {},
};

const UserDataContext = React.createContext(initialState);

export const UserDataProvider = ({
	children,
	splashScreen = null,
}: {
	children: ReactNode;
	splashScreen?: ReactNode;
}) => {
	const firebaseContext = useFirebase();
	const { isUserAuthenticated } = firebaseContext;
	const { setLoading } = useInterface();
	const utils = trpc.useUtils();
	const location = useLocation();

	const getHome = trpc.user.home.useQuery(undefined, {
		enabled: isUserAuthenticated,
	});
	useEffect(() => {
		if (getHome.data?.zendeskIdentification) {
			zendesk.identify(getHome.data.zendeskIdentification);
		}
	}, [getHome.data]);

	const listOnboardingEvents = trpc.user.listOnboardingEvents.useQuery(undefined, { enabled: isUserAuthenticated });
	const hasLineOfCreditReportingEnabled =
		BusinessEventType.OnboardingCreditBuilderLineOfCreditEnabled in (listOnboardingEvents.data ?? {});
	const hasRentReportingEnabled =
		BusinessEventType.OnboardingCreditBuilderRentalConfirmed in (listOnboardingEvents.data ?? {});

	// let the errors bubble to the error boundary
	if (getHome.isError) throw getHome.error;
	if (listOnboardingEvents.isError) throw listOnboardingEvents.error;

	const home = getHome.data;
	const creditBuilderSupported = Boolean(
		!home?.landlord || (home?.landlord && home.landlord.creditBuilder !== PropertyManagerCreditBuilder.Disabled),
	);

	const onboardingCreditBuilderRentalEnd =
		BusinessEventType.OnboardingCreditBuilderRentalEnd in (listOnboardingEvents.data ?? {});
	const onboardingCreditBuilderRentalStart =
		BusinessEventType.OnboardingCreditBuilderRentalStart in (listOnboardingEvents.data ?? {});
	const onboardingCreditBuilderHousingProviderStart =
		BusinessEventType.OnboardingHousingProviderStart in (listOnboardingEvents.data ?? {});
	const hasResidency = Boolean(home?.residency);
	// TODO check for CustomRent as well
	const hasAtLeastOneProductOnboardingCompleted = onboardingCreditBuilderRentalEnd ?? false;
	const creditBuilderAvailableButNotSeenPromo = creditBuilderSupported && !onboardingCreditBuilderRentalStart;
	const hasCompletedAddressOrSurvey = hasResidency || Boolean(home?.hasFilledSignUpSurvey);

	const canExitOnboarding =
		hasCompletedAddressOrSurvey &&
		(hasAtLeastOneProductOnboardingCompleted ||
			creditBuilderAvailableButNotSeenPromo ||
			onboardingCreditBuilderHousingProviderStart ||
			onboardingCreditBuilderRentalStart);

	const userStatus = getUserStatus(
		home,
		listOnboardingEvents.data,
		firebaseContext,
		location.pathname,
		canExitOnboarding,
		creditBuilderSupported,
	);

	if (userStatus === UserStatus.Loading) {
		return <>{splashScreen}</>;
	}

	const silentRefetch = async () => {
		await utils.invalidate();
	};

	const forceRefetch = async () => {
		setLoading(true);
		await utils.invalidate();
		setLoading(false);
	};

	return (
		<UserDataContext.Provider
			value={{
				home: getHome.data ?? null,
				user: getHome.data?.user ?? null,
				canExitOnboarding,
				hasLineOfCreditReportingEnabled,
				hasRentReportingEnabled,
				customRentSupported: home?.landlord?.customRent === LandlordCustomRent.Enabled,
				userStatus,
				handlers: {
					forceRefetch,
					silentRefetch,
				},
				onboardingEvents: listOnboardingEvents.data ?? {},
			}}
		>
			{children}
		</UserDataContext.Provider>
	);
};

export const useUserData = () => {
	const context = React.useContext(UserDataContext);
	if (context === undefined) {
		throw new Error('useUser must be used within a UserDataProvider');
	}
	return context;
};

const getUserStatus = (
	home:
		| Pick<
				RouterOutput['user']['home'],
				| 'hasAnyRent'
				| 'hasFilledSignUpSurvey'
				| 'hasFilledLandlordPaymentMethod'
				| 'userHasUnverifiedBankAccount'
				| 'user'
				| 'pmsResidencyHasRoommates'
				| 'activeRentConfig'
				| 'landlord'
				| 'creditSubject'
				| 'hasExecutingCreditBuilderTransaction'
				| 'hasPendingCreditBuilderHistoryUpsellTransaction'
				| 'residency'
				| 'creditBuilderPricing'
		  >
		| undefined,
	onboardingEvents: RouterOutput['user']['listOnboardingEvents'] | undefined,
	firebaseContext: FirebaseContextState,
	locationPathname: string,
	canExitOnboarding: boolean,
	creditBuilderSupported: boolean,
) => {
	const { authUserLoaded, isUserAuthenticated, isEmailVerified, isPhoneEnrolled } = firebaseContext;
	const user = home?.user;

	const creditBuilderPaidByLandlord =
		home?.landlord && home.landlord.creditBuilder === PropertyManagerCreditBuilder.PaidByLandlord;
	const creditBuilderActive = home?.creditSubject?.rentReportingStatus === CreditSubjectRentReportingStatus.Active;

	const customRentSupported = home?.landlord?.customRent !== LandlordCustomRent.Disabled;

	if (!authUserLoaded) {
		return UserStatus.Loading;
	}

	if (!isUserAuthenticated) {
		return UserStatus.Unauthenticated;
	}

	if (home === undefined || !user || onboardingEvents === undefined) {
		return UserStatus.Loading;
	}

	if (home.residency?.country === Country.US) {
		return UserStatus.CountryNotSupported;
	}
	if ((locationPathname.startsWith(links.ACCOUNT.ROOT) || locationPathname === '/') && canExitOnboarding) {
		if (creditBuilderSupported && !onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalStart]) {
			if (!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalEnabled]) {
				return UserStatus.PromoCreditBuilderSetup;
			}
			if (!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalEnd]) {
				return UserStatus.PromoCreditBuilderSuccess;
			}
		}

		return UserStatus.ValidUser;
	}

	const requiresPhoneEnrollment = !isPhoneEnrolled && !isImpersonating() && user && user.enroll2fa;

	const creditReportingRental = userHasFeatureEnabled(user, FeatureSwitch.CreditReportingRental);
	const paymentAccountActiveMissing =
		!user.paymentCardId && !user.paymentAccountId && !home.userHasUnverifiedBankAccount;

	if (!isImpersonating() && !isEmailVerified) {
		return UserStatus.OnboardingVerifyEmail;
	}

	if (user.claims.admin) {
		return UserStatus.Admin;
	}

	if (user.claims.partner && !userStatusPartnerSkip.get(user.userId)) {
		return UserStatus.Partner;
	}

	if (isEmailVerified && requiresPhoneEnrollment) return UserStatus.OnboardingEnrollPhone;

	// Legacy users do not have onboarding business events
	// short circuit whole onboarding for them
	const legacyUsersShortCircuit = isLegacyUserShortCircuit(home, onboardingEvents);

	if (
		(onboardingEvents[BusinessEventType.OnboardingSuccessEnd] || legacyUsersShortCircuit) &&
		creditBuilderSupported &&
		!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalEnd]
	) {
		if (!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalEnabled]) {
			return UserStatus.PromoCreditBuilderSetup;
		}
		if (!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalEnd]) {
			return UserStatus.PromoCreditBuilderSuccess;
		}
	}

	if (legacyUsersShortCircuit) {
		return UserStatus.ValidUser;
	}

	const hasCompletedAddressOnboarding = Boolean(home.residency);
	if (!hasCompletedAddressOnboarding) return UserStatus.OnboardingAddress;

	if (home.residency?.country === Country.US) {
		return UserStatus.CountryNotSupported;
	}

	if (!home?.hasFilledSignUpSurvey) return UserStatus.PendingOnboardingSurvey;

	// credit builder rental onboarding, shown when:
	// - manual address OR landlord has credit builder enabled
	// - AND user haven't seen rent amount step - this user will see promo later in the app
	if (!onboardingEvents[BusinessEventType.OnboardingCustomRentStart] && creditBuilderSupported) {
		if (!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalEnabled]) {
			return UserStatus.OnboardingCreditBuilderSetup;
		}
		if (
			onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalHistoryUpsellStart] &&
			!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalHistoryUpsellEnd]
		) {
			return UserStatus.OnboardingCreditBuilderHistoryUpsell;
		}
		if (
			!creditBuilderPaidByLandlord &&
			!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalSubscriptionPeriodStart]
		) {
			return UserStatus.OnboardingCreditBuilderSubscriptionSelection;
		}
		if (
			!creditBuilderPaidByLandlord &&
			onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalSubscriptionPeriodStart] &&
			!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalSubscriptionPeriodEnd]
		) {
			return UserStatus.OnboardingCreditBuilderSubscriptionSelection;
		}
		if (
			!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalConfirmed] ||
			(!creditBuilderActive && !home?.hasExecutingCreditBuilderTransaction) ||
			home.hasPendingCreditBuilderHistoryUpsellTransaction
		) {
			return UserStatus.OnboardingCreditBuilderSuccess;
		}

		if (!onboardingEvents[BusinessEventType.OnboardingCreditBuilderRentalEnd]) {
			return UserStatus.OnboardingCreditBuilderPaymentCompleted;
		}

		if (
			onboardingEvents[BusinessEventType.OnboardingCustomRentWaitlistStart] &&
			!onboardingEvents[BusinessEventType.OnboardingCustomRentWaitlistEnd]
		) {
			return UserStatus.OnboardingCustomRentWaitlist;
		}

		if (creditReportingRental) {
			return UserStatus.ValidUser;
		}
	}

	if (
		onboardingEvents[BusinessEventType.OnboardingCustomRentWaitlistStart] &&
		!onboardingEvents[BusinessEventType.OnboardingCustomRentWaitlistEnd]
	) {
		return UserStatus.OnboardingCustomRentWaitlist;
	}

	if (!customRentSupported) return UserStatus.ValidUser;

	if (!home?.hasFilledLandlordPaymentMethod) return UserStatus.PendingLandlordPaymentMethod;

	if (!onboardingEvents[BusinessEventType.OnboardingCustomRentAmountSet]) {
		return UserStatus.OnboardingSelectRentAmount;
	}
	if (
		!onboardingEvents[BusinessEventType.OnboardingRoommatesRentAmountConfirmed] &&
		home.pmsResidencyHasRoommates &&
		!onboardingEvents[BusinessEventType.OnboardingCustomRentDatesConfirmed] // existing users won't be redirected to OnboardingSelectRentAmount
	) {
		return UserStatus.OnboardingRoommatesRentAmountConfirm;
	}
	if (!onboardingEvents[BusinessEventType.OnboardingCustomRentDatesConfirmed]) {
		return UserStatus.OnboardingSelectDates;
	}

	// TODO: items clean-up
	// This can be removed when we stop disconnecting accounts when user decides to
	// connect again or connect a new account
	// User is reconnecting bank or connecting bank from settings and we don't want to
	// redirect to onboarding
	if (home.hasAnyRent && paymentAccountActiveMissing && onboardingEvents[BusinessEventType.OnboardingSuccessEnd]) {
		return UserStatus.ValidUser;
	}
	if (paymentAccountActiveMissing) return UserStatus.OnboardingBank;

	const hasCreditSubject = home?.creditSubject?.id;
	if (
		!onboardingEvents[BusinessEventType.OnboardingSuccessEnd] &&
		!onboardingEvents[BusinessEventType.OnboardingCreditBuilderLineOfCreditEnabled] &&
		!hasCreditSubject
	) {
		return UserStatus.OnboardingLineOfCreditReporting;
	}

	if (!onboardingEvents[BusinessEventType.OnboardingCustomRentConfirmed]) return UserStatus.OnboardingConfirm;

	// disabled on production
	if (getEnvironment() !== 'production' || user?.email.includes('directdebitenabled@myzenbase.com')) {
		if (
			!onboardingEvents[BusinessEventType.OnboardingCustomRentDirectDebitSuccessConfirmed] &&
			!onboardingEvents[BusinessEventType.OnboardingCustomRentDirectDebitDeclined]
		) {
			if (!onboardingEvents[BusinessEventType.OnboardingCustomRentDirectDebitStart]) {
				return UserStatus.OnboardingDirectDebitSetup;
			}
			if (!onboardingEvents[BusinessEventType.OnboardingCustomRentDirectDebitSuccess]) {
				return UserStatus.OnboardingDirectDebitCardDetails;
			}
			if (!onboardingEvents[BusinessEventType.OnboardingCustomRentDirectDebitSuccessConfirmed]) {
				return UserStatus.OnboardingDirectDebitCardSuccess;
			}
		}
	}

	if (!onboardingEvents[BusinessEventType.OnboardingSuccessEnd]) return UserStatus.SuccessSteps;

	return UserStatus.ValidUser;
};

export const useUserFeature = (feature: FeatureSwitch) => {
	const { user } = useUserData();
	return user ? userHasFeatureEnabled(user, feature) : undefined;
};

export const userStatusPartnerSkip = {
	skip(userId: string) {
		sessionStorage.setItem(`partner-skip-${userId}`, 'true');
	},
	get(userId: string) {
		return Boolean(sessionStorage.getItem(`partner-skip-${userId}`));
	},
};

export const useCreditBuilderDashboard = () => {
	const { user, home } = useUserData();

	const { data: onboardingEvents } = trpc.user.listOnboardingEvents.useQuery(undefined, {
		refetchOnWindowFocus: false,
	});
	const creditReportingRental = user ? userHasFeatureEnabled(user, FeatureSwitch.CreditReportingRental) : false;

	const creditBuilder = Boolean(
		onboardingEvents &&
			creditReportingRental &&
			!(
				onboardingEvents[BusinessEventType.OnboardingSuccessEnd] ||
				(home ? isLegacyUserShortCircuit(home, onboardingEvents) : false)
			),
	);

	return creditBuilder;
};

const isLegacyUserShortCircuit = (
	home: Pick<RouterOutput['user']['home'], 'hasAnyRent'>,
	onboardingEvents: RouterOutput['user']['listOnboardingEvents'],
) => {
	return home.hasAnyRent && !onboardingEvents[BusinessEventType.OnboardingCustomRentStart];
};
