import React, { useEffect } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { addYears, parse } from 'date-fns';
// but in date-fns + parce = format cannot be imported as named import
import format from 'date-fns/format';
import { FormProvider, useForm } from 'react-hook-form';
import { FormattedMessage, useIntl } from 'react-intl';
import { z } from 'zod';

import { BusinessEventType } from '../../../../functions/src/shared/business-events';
import { isoDate, isoDateToJsDate } from '../../../../functions/src/shared/iso-date';
import { UserPmsResidencyType } from '../../../../functions/src/shared/pms';

import { trpc } from '../../shared/trpc/client';

import { Button, Footer, Spacing, Text } from '../../base-ui/components';

import { AlertError } from '../components/alert-error';
import { FormattedDateInput } from '../components/formatted-date-input';
import { useIndirectLeaseDateSchema } from '../components/use-indirect-lease-date-schema.hook';
import { UserStatus, useUserData } from '../contexts/user-data-context';
import { shouldPrefill } from '../utils/handover-should-prefill';
import { useTrackOnboardingEvent } from '../utils/track-onboarding-event';

type Props = {
	onNext: () => void;
};

export const IndirectSelectLeaseDates = ({ onNext }: Props) => {
	const trpcUtils = trpc.useUtils();
	const trackOnboardingEvent = useTrackOnboardingEvent();
	useEffect(() => trackOnboardingEvent.mutate({ type: BusinessEventType.OnboardingIndirectLeaseDatesStart }), []);

	const intl = useIntl();
	const schema = useIndirectLeaseDateSchema(intl);
	type Schema = z.infer<typeof schema>;

	const parseDateStr = (dateStr: string) => {
		try {
			return dateStr ? parse(dateStr, 'MM/dd/yyyy', new Date()) : new Date();
		} catch {
			return new Date();
		}
	};
	const { home, userStatus } = useUserData();
	const startDateToPrefill =
		userStatus !== UserStatus.ValidUser &&
		home?.residency?.type === UserPmsResidencyType.Indirect &&
		home?.residency.residentStartDate
			? // @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
				format(isoDateToJsDate(home.residency.residentStartDate), 'MM/dd/yyyy')
			: undefined;

	const endDateToPrefill =
		shouldPrefill({ userStatus, home }) && home?.residency?.residentEndDate
			? // @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
				format(isoDateToJsDate(home.residency.residentEndDate), 'MM/dd/yyyy')
			: undefined;

	const form = useForm<Schema>({
		resolver: zodResolver(schema),
		defaultValues: {
			leaseStartDate: startDateToPrefill,
			leaseEndDate: endDateToPrefill,
		},
	});

	const saveLeaseDates = trpc.user.selectIndirectLeaseDates.useMutation({
		onSuccess: () => {
			trpcUtils.user.home.invalidate();
			onNext();
		},
	});

	const submit = async ({ leaseStartDate, leaseEndDate }: Schema) => {
		saveLeaseDates.mutate({ leaseStart: isoDate(leaseStartDate), leaseEnd: isoDate(leaseEndDate) });
	};

	const [startDate, endDate] = form.watch(['leaseStartDate', 'leaseEndDate']);
	return (
		<FormProvider {...form}>
			<form onSubmit={form.handleSubmit(submit)}>
				<Text type="title">
					<FormattedMessage defaultMessage="Enter your lease dates" id="indirect-select-lease-dates.title" />
				</Text>
				<Text type="body">
					<FormattedMessage
						defaultMessage="Please enter start and end date of your lease."
						id="indirect-select-lease-dates.body"
					/>
				</Text>
				<Spacing $size="m" />
				<FormattedDateInput
					name="leaseStartDate"
					placeholder={intl.formatMessage({
						defaultMessage: 'Lease Start Date',
						id: 'indirect-select-lease-dates.placeholder-lease-start-date',
					})}
					testId="onboarding-indirect-lease-dates-input-lease-start-date"
					changeUntil={addYears(new Date(), 2)}
					handleSelect={(d: Date | undefined) => {
						d ??= parseDateStr(startDate);
						// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
						form.setValue('leaseStartDate', format(d, 'MM/dd/yyyy'));
						if (!endDate) {
							// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
							form.setValue('leaseEndDate', format(addYears(d, 1), 'MM/dd/yyyy'));
						}
					}}
					parseDate={parseDateStr}
				/>
				<FormattedDateInput
					name="leaseEndDate"
					testId="onboarding-indirect-lease-dates-input-lease-end-date"
					placeholder={intl.formatMessage({
						defaultMessage: 'Lease End Date',
						id: 'indirect-select-lease-dates.placeholder-lease-end-date',
					})}
					changeUntil={addYears(new Date(), 10)}
					handleSelect={function (d: Date | undefined): void {
						if (!d) return;
						// @ts-expect-error parcel invalid import https://github.com/parcel-bundler/parcel/issues/9676
						form.setValue('leaseEndDate', format(d, 'MM/dd/yyyy'));
						form.clearErrors('leaseEndDate');
					}}
					parseDate={parseDateStr}
				/>
				{saveLeaseDates.isError && <AlertError />}
				<Footer>
					<Button
						disabled={saveLeaseDates.isPending}
						htmlType="submit"
						testId="onboarding-indirect-lease-dates-button-confirm"
					>
						<FormattedMessage defaultMessage="Continue" id="common.continue" />
					</Button>
				</Footer>
			</form>
		</FormProvider>
	);
};
