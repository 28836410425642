import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';

import { trpc } from '../../shared/trpc/client';

import { Alert, AlertText, AlertTitle, Loader, Text } from '../../base-ui/components';

import { BackButton } from '../components/back-button';
import { OnboardingCloseButton } from '../components/onboarding-close-button';
import { GENERIC_STRIPE_ERROR_MESSAGE } from '../components/stripe/stripe-errors';
import { useStripeCheckout } from '../components/stripe/use-stripe-checkout';
import { links } from '../Router/paths';
import { ConnectCard } from '../templates/connect-card';

export const Checkout = () => {
	const [search, setSearch] = useSearchParams();
	const ptid = search.get('ptid') ?? '';
	const successPath = search.get('successPath') ?? '';

	const [confirmed, setConfirmed] = useState(false);

	const { paymentDetails, confirmationError, paymentMethodForm, clearState } = useStripeCheckout({
		ptid,
		successPath,
	});

	const invalidTransaction = !!paymentDetails.error || paymentDetails?.data?.invalidTransactionStatus === true;

	const createNewPayment = trpc.user.paymentCards.recreateFailedTransaction.useMutation({
		onSuccess({ ptid: newPtid }) {
			const newParams = new URLSearchParams(search);
			newParams.set('ptid', newPtid);
			setSearch(newParams);
			setConfirmed(true);
		},
	});

	function handleEnsureValidTransaction() {
		clearState();
		if (invalidTransaction) {
			createNewPayment.mutate({ ptid });
			return;
		}
		setConfirmed(true);
	}

	useEffect(() => {
		if (confirmed && (invalidTransaction || confirmationError)) {
			setConfirmed(false);
		}
	}, [invalidTransaction, confirmationError, confirmed]);

	if (paymentDetails.isPending) {
		return (
			<>
				<Text center>
					<FormattedMessage
						defaultMessage="Initializing payment form..."
						id="checkout.initializing-payment-form"
					/>
				</Text>
				<FormLoader>
					<Loader />
				</FormLoader>
			</>
		);
	}

	return (
		<>
			<OnboardingCloseButton overrideCanExit to={links.ACCOUNT.DASHBOARD} />
			{!confirmed && (
				<>
					{(invalidTransaction || confirmationError) && (
						<Alert>
							<AlertTitle>
								<FormattedMessage defaultMessage="Payment failed" id="checkout.payment-failed" />
							</AlertTitle>
							<AlertText>
								{!confirmationError && GENERIC_STRIPE_ERROR_MESSAGE}
								{!!confirmationError && confirmationError}
							</AlertText>
						</Alert>
					)}
					<ConnectCard onContinue={handleEnsureValidTransaction} />
				</>
			)}

			<FormContainer $visible={confirmed}>
				<BackButton onClick={() => setConfirmed(false)} />
				<Text type="title">
					<FormattedMessage
						defaultMessage="Enter credit card details"
						id="checkout.enter-credit-card-details"
					/>
				</Text>
				{paymentMethodForm}
				{invalidTransaction && (
					<Alert>
						<AlertTitle>
							<FormattedMessage
								defaultMessage="Failed to prepare payment"
								id="checkout.failed-to-prepare-payment"
							/>
						</AlertTitle>
						<AlertText>
							<FormattedMessage
								defaultMessage="Please try again or contact support if the problem persists."
								id="checkout.try-again-or-contact-support"
							/>
						</AlertText>
					</Alert>
				)}
			</FormContainer>
		</>
	);
};

const FormContainer = styled.div<{ $visible: boolean }>`
	visibility: ${({ $visible }) => ($visible ? 'visible' : 'hidden')};
	height: ${({ $visible }) => ($visible ? 'auto' : 0)};
	overflow: ${({ $visible }) => ($visible ? 'unset' : 'hidden')};
`;

const FormLoader = styled.div`
	position: absolute;
	z-index: 0;
	inset: 0;
	display: flex;
	align-items: center;
	justify-content: center;
`;
