import styled, { keyframes } from 'styled-components';

import { colors } from '../../../../base-ui/variables';

const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

export const Spinner = styled.div<{ $spinnerColor: 'green' | 'white' }>`
	border: 4px solid rgba(255, 255, 255, 0.3);
	border-top: 4px solid ${({ $spinnerColor }) => ($spinnerColor === 'green' ? colors.green : colors.white)};
	border-radius: 50%;
	width: 24px;
	height: 24px;
	animation: ${spin} 1s linear infinite;
	margin: 20px auto;
`;

export const Card = styled.div<{ type: 'positive' | 'negative' }>`
	background-color: ${({ type }) => (type === 'positive' ? colors.green : colors.alertBackground)};
	border-radius: 20px;
	padding: 10px 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	position: relative;
	overflow: hidden;
	color: ${({ type }) => (type === 'positive' ? colors.white : colors.red)};
	border-radius: 20px;
	padding: 10px 20px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	position: relative;
	overflow: hidden;
`;

export const Header = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px;
`;

export const Title = styled.p`
	font-size: 2rem;
	font-weight: 500;
	line-height: 1.2;
	padding-top: 5px;
`;

export const PaymentCountText = styled.h2`
	font-size: 32px;
	font-weight: 500;
	text-align: center;
	margin-top: 10px;
	@media (max-width: 768px) {
		font-size: 24px;
	}
`;
export const PaymentHistoryUpsellText = styled.div`
	@media (max-width: 450px) {
		font-size: 1.3rem;
	}
`;

export const NotReportingText = styled.h2`
	font-size: 20px;
	margin-top: 15px;
	margin-right: 15px;
	padding: 0 30px;
	@media (max-width: 768px) {
		font-size: 16px;
		margin-right: 0;
		margin-bottom: 20px;
	}
`;

export const Reporting = styled.div`
	display: flex;
	font-size: 14px;
	@media (max-width: 768px) {
		font-size: 12px;
	}
`;

export const CheckIcon = styled.img`
	display: inline-block;
	margin-left: 5px;
	width: 20px;
	height: 20px;
	vertical-align: middle;
`;

export const StartDateText = styled.div`
	margin-top: 15px;
	text-align: center;
	font-size: 14px;
	@media (max-width: 768px) {
		font-size: 12px;
	}
`;

export const OverlayImage = styled.img<{ type: 'positive' | 'negative' }>`
	position: absolute;
	${({ type }) =>
		type === 'positive'
			? `
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  `
			: `
    bottom: -20px;
    right: -20px;
    width: 30%;
    height: auto;
  `}
`;

export const Content = styled.div`
	position: relative;
	z-index: 1; /* To ensure content appears above the image */
`;

export const ButtonBox = styled.div`
	display: block;
	margin: 20px auto;
	width: 50%;
	max-width: 100%;
	@media (max-width: 768px) {
		width: fit-content;
	}
`;

export const Footer = styled.div`
	margin-top: 40px;
	@media (max-width: 768px) {
		margin-top: 20px;
	}
`;
