import React from 'react';
import { FormattedMessage } from 'react-intl';
import success from 'url:../../static/shapes/connect-bank.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { trpc } from '../../../shared/trpc/client';

import { Box, Spacing } from '../../../base-ui/components';

import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';

type Props = {
	onSuccess?: () => void;
};
export const DirectDebitCardSuccess = ({ onSuccess }: Props) => {
	const trackEvent = useTrackOnboardingEvent();
	const utils = trpc.useUtils();

	function handleNext() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitSuccessConfirmed },
			{
				onSuccess: () => {
					utils.user.listOnboardingEvents.invalidate();
					onSuccess?.();
				},
			},
		);
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={success} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Your debit card is verified"
							id="custom-rent-onboarding.direct-debit-success.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Your debit card is successfully verified. No charges were made to your card at this point. Your card will be charged when your payment is due."
							id="custom-rent-onboarding.direct-debit-success.text"
						/>
					}
					cta={<FormattedMessage defaultMessage="Continue" id="common.continue" />}
					onNext={handleNext}
					ctaTestId="direct-debit-card-success-continue-button"
					ctaDisabled={trackEvent.isPending}
					backButton={false}
				/>
			</Box>
		</>
	);
};
