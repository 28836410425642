import React from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../../functions/src/shared/business-events';
import { IsoDate, isoDateToJsDate } from '../../../../../../functions/src/shared/iso-date';
import { PropertyManagerCreditBuilder } from '../../../../../../functions/src/shared/landlords';
import { ensureExhaustiveCheck } from '../../../../../../functions/src/shared/utils';

import { RouterOutput, trpc } from '../../../../shared/trpc/client';

import { useUserData } from '../../../contexts/user-data-context';
import { links } from '../../../Router/paths';
import { creditBuilderErrorService, CreditBuilderErrorState } from './credit-builder-error.service';
import * as S from './profile-errors.style';

type CreditSubject = RouterOutput['creditReporting']['getUserInfo'];

type ErrorBoxProps = {
	errorState: CreditBuilderErrorState | null;
	creditSubject: CreditSubject | null;
};

function ErrorBox({ errorState, creditSubject }: ErrorBoxProps) {
	const navigate = useNavigate();

	const onClickLinkHandler = creditBuilderErrorService.getOnClickLinkHandler(navigate, {
		errorState,
		creditSubject,
	});

	if (errorState === null || onClickLinkHandler === null) {
		return null;
	}

	switch (errorState) {
		case 'upload-lease-agreement-pending':
			return (
				<S.Wrapper onClick={onClickLinkHandler}>
					<S.Title>
						<FormattedMessage
							defaultMessage="Please upload your lease agreement to verify your lease details."
							id="credit-builder-profile-errors.upload-lease-agreement"
						/>{' '}
						<S.Link>
							<FormattedMessage defaultMessage="Click to proceed" id="common.click-to-proceed" />
						</S.Link>
						.
					</S.Title>
				</S.Wrapper>
			);
		case 'pending-payment':
			return (
				<S.Wrapper onClick={onClickLinkHandler}>
					<S.Title>
						<FormattedMessage
							defaultMessage="Pending CreditBuilder payment,"
							id="credit-builder-profile-errors.pending-payment"
						/>{' '}
						<S.Link>
							<FormattedMessage defaultMessage="Click to proceed" id="common.click-to-proceed" />
						</S.Link>
						.
					</S.Title>
				</S.Wrapper>
			);
		case 'pending-confirmation':
			return (
				<S.Wrapper onClick={onClickLinkHandler}>
					<S.Title>
						<FormattedMessage
							defaultMessage="Pending CreditBuilder terms confirmation,"
							id="credit-builder-profile-errors.pending-confirmation"
						/>{' '}
						<S.Link>
							<FormattedMessage defaultMessage="Click to proceed" id="common.click-to-proceed" />
						</S.Link>
						.
					</S.Title>
				</S.Wrapper>
			);
		case 'lease-agreement-update-requested':
			return (
				<S.Wrapper onClick={onClickLinkHandler}>
					<S.Title>
						<FormattedMessage
							defaultMessage="Your lease cannot be verified."
							id="credit-builder-profile-errors.lease-agreement-update-requested"
						/>{' '}
						<S.Link>
							<FormattedMessage
								defaultMessage="Please re-upload your lease."
								id="reupload-lease-agreement.title"
							/>
						</S.Link>
					</S.Title>
				</S.Wrapper>
			);
		case 'indirect-rent-amount-missing':
		case 'pending-setup':
		case 'indirect-lease-dates-missing':
			return (
				<S.Wrapper onClick={onClickLinkHandler}>
					<S.Title>
						<FormattedMessage
							defaultMessage="Please finish CreditBuilder onboarding."
							id="credit-builder-profile-errors.please-finish-onboarding"
						/>{' '}
						<S.Link>
							<FormattedMessage defaultMessage="Click to proceed" id="common.click-to-proceed" />
						</S.Link>
						.
					</S.Title>
				</S.Wrapper>
			);
		case 'indirect-last-month-of-lease':
			return (
				<S.Wrapper onClick={onClickLinkHandler}>
					<S.Title>
						<FormattedMessage
							defaultMessage="Update your rental agreement to continue building credit."
							id="credit-builder-profile-errors.indirect-update-rental-agreement"
						/>{' '}
						<S.Link>
							<FormattedMessage defaultMessage="Click to proceed" id="common.click-to-proceed" />
						</S.Link>
					</S.Title>
				</S.Wrapper>
			);

		default:
			ensureExhaustiveCheck(errorState);
	}
}

export const CreditBuilderProfileErrors = () => {
	const { home } = useUserData();
	const { data: creditSubject, isPending: creditSubjectLoading } = trpc.creditReporting.getUserInfo.useQuery();
	const { creditBuilderSetupIncomplete } = useCreditBuilderSetupIncomplete();

	const errorState = creditBuilderErrorService.resolveErrorState({
		home,
		creditSubject,
		setupIncomplete: creditBuilderSetupIncomplete,
	});

	const landlord = home?.landlord;

	const landlordHasDisabledCreditBuilder = landlord?.creditBuilder === PropertyManagerCreditBuilder.Disabled;

	if (landlordHasDisabledCreditBuilder || creditSubjectLoading) {
		return null;
	}

	return (
		<>
			<ErrorBox errorState={errorState} creditSubject={creditSubject ?? null} />
		</>
	);
};

export const CreditBuilderHistoryError = () => {
	const { data, isLoading } = trpc.creditReporting.creditBuilderHistoryRelatedInfo.useQuery();
	const navigate = useNavigate();

	if (isLoading) return null;
	if (!data) return null;
	if (
		!data.manualResidencyForHistoryWithoutLeaseAgreement?.id &&
		!data.manualResidencyForHistoryWithUpdateRequested?.id
	)
		return null;
	if (!data.canAddMoreHistory) return null;

	const manualResidencyId =
		data.manualResidencyForHistoryWithoutLeaseAgreement?.id ??
		data.manualResidencyForHistoryWithUpdateRequested?.id;

	const errorState: 'add-lease' | 'update-lease' | null = data.manualResidencyForHistoryWithoutLeaseAgreement
		? 'add-lease'
		: data.manualResidencyForHistoryWithUpdateRequested
			? 'update-lease'
			: null;

	if (errorState === null) return null;

	return (
		<>
			<S.Wrapper
				onClick={() =>
					navigate(links.ACCOUNT.UPLOAD_LEASE_AGREEMENTS, {
						state: { manualResidencyId },
					})
				}
			>
				<S.Title>
					<CreditBuildeErrorContent errorState={errorState} dateOpened={data.dateOpened} />{' '}
					<S.Link>
						<FormattedMessage defaultMessage="Tap to proceed" id="common.tap-to-proceed" />
					</S.Link>
				</S.Title>
			</S.Wrapper>
		</>
	);
};

const CreditBuildeErrorContent = ({
	errorState,
	dateOpened,
}: {
	errorState: 'add-lease' | 'update-lease';
	dateOpened: IsoDate | null | undefined;
}) => {
	if (errorState === 'add-lease') {
		return (
			<FormattedMessage
				defaultMessage="Add your pre-{startDate} rent payments to build credit faster."
				id="credit-builder-profile-errors.manual-residency-for-credit-builer-history-missing-lease-agreement"
				values={{
					startDate: dateOpened ? (
						<FormattedDate value={isoDateToJsDate(dateOpened)} month="long" year="2-digit" />
					) : (
						<FormattedMessage
							defaultMessage="start date"
							id="credit-builder-profile-errors.manual-residency-for-credit-builer-history-missing-lease-agreement-no-date"
						/>
					),
				}}
			/>
		);
	}
	if (errorState === 'update-lease') {
		return (
			<FormattedMessage
				defaultMessage="Update your lease agreement to build credit faster."
				id="credit-builder-profile-errors.update-credit-builder-history-lease-agreement"
			/>
		);
	}
	return null;
};

export const useCreditBuilderSetupIncomplete = () => {
	const listOnboardingEvents = trpc.user.listOnboardingEvents.useQuery();
	const onboardingEvents = listOnboardingEvents.data ?? {};

	const creditBuilderSetupIncomplete =
		BusinessEventType.OnboardingHousingProviderStart in onboardingEvents &&
		!(BusinessEventType.OnboardingCreditBuilderRentalEnd in onboardingEvents);

	return { creditBuilderSetupIncomplete };
};
