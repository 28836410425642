import React from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import directDebitOk from 'url:../../static/shapes/connect-bank.png';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { Box, Spacing } from '../../../base-ui/components';

import { useInterface } from '../../contexts/hooks';
import { paths } from '../../Router/paths';
import { OnboardingSuccess } from '../../templates/onboarding-success';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { DirectDebitCardCancelModal } from './direct-debit-card-cancel-modal';

export const DirectDebitSetup = () => {
	const navigate = useNavigate();
	const trackEvent = useTrackOnboardingEvent();
	const { setModal } = useInterface();

	function handleNext() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitStart },
			{
				onSuccess: () => {
					navigate(`../${paths.REGISTRATION.DIRECT_DEBIT.CARD_DETAILS}`);
				},
			},
		);
	}
	function handleDecline() {
		setModal(<DirectDebitCardCancelModal />);
	}

	return (
		<>
			<Box isColumn verticalCenter>
				<img src={directDebitOk} alt="" width="335" />
				<Spacing $size="m" />

				<OnboardingSuccess
					title={
						<FormattedMessage
							defaultMessage="Fast and secure payments"
							id="custom-rent-onboarding.direct-debit-setup.title"
						/>
					}
					subtitle={
						<FormattedMessage
							defaultMessage="Debit card is the fastest and safest way to make your payments. It protects you from NSF fees, avoids any delays and safely automates your payments. Only for 0.5% of your transaction amount."
							id="custom-rent-onboarding.direct-debit-setup.text"
						/>
					}
					cta={
						<FormattedMessage
							defaultMessage="Add my debit card"
							id="custom-rent-onboarding.direct-debit-setup.confirm"
						/>
					}
					secondaryCta={
						<FormattedMessage
							defaultMessage="Not at this point"
							id="custom-rent-onboarding.direct-debit-setup.decline"
						/>
					}
					ctaTestId="direct-debit-setup-continue-button"
					onNext={handleNext}
					onSecondaryCta={handleDecline}
				/>
			</Box>
		</>
	);
};
