import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { BusinessEventType } from '../../../../../functions/src/shared/business-events';

import { trpc } from '../../../shared/trpc/client';

import { MonerisCheckout } from '../../components/moneris/moneris-checkout';
import { useInterface, useUserData } from '../../contexts/hooks';
import { paths } from '../../Router/paths';
import { useTrackOnboardingEvent } from '../../utils/track-onboarding-event';
import { DirectDebitCardCancelModal } from './direct-debit-card-cancel-modal';

export const DirectDebitCardDetails = () => {
	const trackEvent = useTrackOnboardingEvent();
	const { setModal } = useInterface();
	const { user } = useUserData();
	const navigate = useNavigate();
	const utils = trpc.useUtils();

	function handleSuccess() {
		trackEvent.mutate(
			{ type: BusinessEventType.OnboardingCustomRentDirectDebitSuccess },
			{
				onSuccess: () => {
					utils.user.listOnboardingEvents.invalidate();
					navigate(`../${paths.REGISTRATION.DIRECT_DEBIT.SUCCESS}`);
				},
			},
		);
	}

	function handleCancel() {
		setModal(<DirectDebitCardCancelModal />);
	}

	useEffect(() => {
		if (user?.paymentCardId) {
			// eslint-disable-next-line no-console
			console.log('User has payment card already, redirecting to success page');
			navigate('/');
		}
	}, [user]);

	if (user?.paymentCardId) {
		return;
	}

	return <MonerisCheckout onSuccess={handleSuccess} onCancel={handleCancel} />;
};
